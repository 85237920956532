import React, {useState,useRef} from 'react'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { validateEmail } from '../../helpers/utils'
import { login } from '../../services/authService'

export default function SignInForm({handleForgotPassword, loginError}) {

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    
    // const loginError = useRef()
    const handleSignIn = () => {
        if ((validateEmail(username)) && (password.length > 0)) {
            login(username, password)
            .then(() => window.location.reload())
            .catch((e) => loginError.current.show({severity: 'error', detail: e}))
        }
    }
    return (
        <div>
            <label htmlFor="email2" className="block text-900 font-medium mb-2">Email</label>
            <InputText id="email2" value={username} type="text" className="w-full mb-3" onChange={(e) => setUsername(e.target.value)} />

            <label htmlFor="password2" className="block text-900 font-medium mb-2">Password</label>
            <InputText id="password2" value={password} type="password" className="w-full mb-3" onChange={(e) => setPassword(e.target.value)} />

            <div className="flex align-items-center justify-content-between mb-6">
                <div className="flex align-items-center">
                    {/* <Checkbox id="rememberme2" binary className="mr-2" checked={checked2} onChange={(e) => setChecked2(e.checked)} /> */}
                    {/* <label htmlFor="rememberme2">Remember me</label> */}
                </div>
                <a className="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer" onClick={handleForgotPassword}>Forgot your password?</a>
            </div>

            <Button onClick={handleSignIn} label="Sign In" icon="pi pi-user" className="w-full" />
        </div>
    )
}

import React from 'react'

export default function BigBadge({number, text}) {
  return (
    
    <div style={style} className=' bg-primary p-0 border-circle justify-content-center flex align-items-center '>
      <div style={{width: 210, height: 210}} className='bg-white p-5 border-circle justify-content-center flex align-items-center flex-column '>
        <p className='text-8xl my-0 font-bold text-color-secondary'>{number}</p>
        <p className='text-3xl my-0 text-center text-color-secondary'>{text}</p>
      </div>
    </div>
  )
}

const style={
    width: 230, height: 230,
    background: 'rgb(17,84,193) linear-gradient(90deg, rgba(17,84,193,1) 42%, rgba(0,212,255,1) 100%)'
  }
import { logout } from "../services/authService";

export const items = [
    {
       label:'Company',
       icon:'pi pi-fw pi-file',
       
       items: [
           {
               label: 'Contenders',
               url: '/contenders'
           },
           {
               label: 'Points',
               url: '/points'
           }
           
       ]
    },
    {
        label: 'Reports',
        items: [
            {
                label: 'Inactive users'
            },
            {
                label: 'Top contenders'
            },
            {
                label: ''
            }
        ]
    },
    {
        label:'Logout',
        icon:'pi pi-fw pi-power-off',
        command: logout
     }

    
 ];
 
import React, { useState, useEffect } from 'react'
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/client";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button'
import { useNavigate } from 'react-router-dom'
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { ProgressSpinner } from 'primereact/progressspinner';

const Q = gql`query MyQuery($where: point_bool_exp = {}) {
  point(where: $where) {
    activity: activity_ {
      title
    }
    category
    contender: account {
      username
      profile {
        first_name
        last_name
      }
    }
    created_at
    day
    id
    notes
    points
    updated_at
  }
}

`
export default function Points(props) {
  let where_clause = props.account ? { account: { id: { _eq: props.account } } } : {}
  const { loading: qLoading, data: qData, error: qError } = useQuery(Q, { variables: { "where": where_clause } })
  const [points, setPoints] = useState([])
  let navigate = useNavigate();

  const [filters2, setFilters2] = useState({
    'contender.profile.first_name': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    'email': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    'activity.title': { value: null, matchMode: FilterMatchMode.CONTAINS },
    'representative': { value: null, matchMode: FilterMatchMode.IN },
    'status': { value: null, matchMode: FilterMatchMode.EQUALS },
    'verified': { value: null, matchMode: FilterMatchMode.EQUALS }
  });

  useEffect(() => {
    if (qData) {
      setPoints(qData.point)
    }
  }, [qData])

  const handleDeletePoint = point => {
    alert(`Delete point ${point.id}`)
  }

  const deleteButton = (row) => {
    return <Button className="bg-pink-500 border-pink-600" onClick={() => handleDeletePoint(row)} type="button" icon="pi pi-trash"></Button>;
  }

  const fullName = (row) => {
    if (row.contender == null) {
      return "Deleted account"
    }
    return `${row.contender.profile?.first_name || "??"}  ${row.contender.profile?.last_name || "??"}`
  }

  if(qLoading) {
    return (
    <div className="w-full flex justify-center mt-4"><ProgressSpinner /></div>
    )
  }
  return (
    <div className="">
      <h1 className=" pl-3">{props.title || "Company points"}</h1>


      <DataTable
        paginator
        value={points}
        rows={10}
        rowsPerPageOptions={[1, 10, 25, 50]}
        responsiveLayout="stack" breakpoint="1095px"
        emptyMessage="No points endered yet! Crack the whip."
      >
        <Column body={fullName} header="Name"></Column>
        <Column field={"contender.username"} header="Email" sortable></Column>
        <Column field={"day"} header="Day"></Column>
        <Column field={"category"} header="Category" sortable></Column>
        <Column field={"activity.title"} header="Activity"></Column>
        <Column field={"notes"} header="Notes"></Column>
        <Column field={"points"} header="Points"></Column>
        {/* <Column header="Last updated"></Column> */}
        <Column headerStyle={{ width: '4rem', textAlign: 'center' }} bodyStyle={{ textAlign: 'center', overflow: 'visible' }} body={deleteButton} />

      </DataTable>
    </div>
  )

}

import React, { useEffect, useState } from 'react';
import { Chart } from 'primereact/chart';

const DoughnutChartDemo = ({data}) => {
    const _data = {...data}
    var groupBy = function(data, key) { // `data` is an array of objects, `key` is the key (or property accessor) to group by
        // reduce runs this anonymous function on each element of `data` (the `item` parameter,
        // returning the `storage` parameter at the end
        return data?.reduce(function(storage, item) {
          // get the first instance of the key by which we're grouping
          var group = item[key];
          
          // set `storage` for this instance of group to the outer scope (if not empty) or initialize it
          storage[group] = storage[group] || [];
          
          
          // add this item to its group within `storage`
          storage[group].push(item);
          
          // return the updated storage to the reduce function, which will then loop through the next 
          return storage; 
        }, {}); // {} is the initial value of the storage
      };

    // let sumCategory = (data) => {
    //     return data?.reduce((storage, item) => {
    //         storage += item.points
    //         return storage
    //     }, 0)
    // }
    useEffect(() => {
        const group = groupBy(data?.point?.nodes,'category')
        const reduced = Object.entries(group).reduce((storage, item) => {
            try {
                storage[item[0]] = storage[item[0]]

            }
            catch {
                storage[item[0]] = []
            }
            storage[item[0]] = item[1].reduce((storage, item) => {
                return storage += item.points
            },0)

            return storage
        },{})
        console.log(Object.values(reduced))
        setPointsData(reduced)
    },[])
    // console.log(sumCategory(['questions']))
    const [pointsData, setPointsData] = useState([])
    const chartData = {
        labels: Object.keys(pointsData),
        datasets: [
            {
                data: Object.values(pointsData),
                backgroundColor: [
                    '#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6', 
		  '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
		  '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A', 
		  '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
		  '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC', 
		  '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
		  '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680', 
		  '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
		  '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3', 
		  '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'
                ],
                hoverBackgroundColor: [
                    '#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6', 
		  '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
		  '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A', 
		  '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
		  '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC', 
		  '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
		  '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680', 
		  '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
		  '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3', 
		  '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'
                ]
            }]
    };

    const [lightOptions] = useState({
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        }
    });

    return (
            <Chart type="doughnut" data={chartData} options={lightOptions} style={{ position: 'relative', width: '80%' }} />

    )
}

export default DoughnutChartDemo



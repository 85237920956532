import React from 'react'
import { Chart } from 'primereact/chart';

export default function PolarPoints(props) {

    // Get categories / colours

    const data = props.contender.points.reduce((catPoints, point) => {
        if (catPoints[point.category] === undefined) catPoints[point.category] = 0
        catPoints[point.category] += point.points
        return catPoints

    }, {})

    const chartData = {
        datasets: [{
            data: Object.values(data),
            backgroundColor: [
                "#42A5F5",
                "#66BB6A",
                "#FFA726",
                "#26C6DA",
                "#7E57C2",
                "#42A5F5",
                "#66BB6A",
                "#FFA726",
                "#26C6DA",
                "#7E57C2",
            ],
            label: 'Points'
        }],
        labels: Object.keys(data)
    };

    const lightOptions = {
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        },
        scales: {
            r: {
                grid: {
                    color: '#bbbbbb',
                    
                }
            }
        }
    };
    return (
        <div className="border-round border-500 border-solid border-1 p-4 flex justify-content-center">
            <Chart type="polarArea" data={chartData} options={lightOptions} style={{ position: 'relative', width: '100%'}} />
        </div>
    )
}

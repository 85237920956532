import React, { useEffect, useState } from 'react'
import { useQuery, useLazyQuery } from "@apollo/client";
import { gql } from "apollo-boost";
import { getFullName } from '../../helpers/utils';
import Donut from '../../components/dashboard/Donut'
import BigBadge from '../../components/dashboard/BigBadge';

const pointsQ = gql`query MyQuery {
  categories: point(distinct_on: category) {
    category
  }
  point: point_aggregate{
    total: aggregate {
      count: sum {
        points
      }
    }
    nodes {
      points
      category
    }
  }
}
`
const Q = gql`query MyQuery {
  company {
    logo
    name
    id
    contender: accounts_aggregate {
      total: aggregate {
        count
      }
    }
    contenders:accounts {
      profile {
        first_name
        last_name
        email
      }
    }
  }
}

`

export default function Company(props) {
  const [company, setCompany] = useState(null)
  const [categoryLabels, setCategoryLabels] = useState([])
  const { loading: qLoading, data: qData, error: qError } = useQuery(Q, {})
  const { loading: pointsQLoading, data: pointsQData, error: pointsQError } = useQuery(pointsQ, {})

  useEffect(() => {
    if (qData) {
      setCompany(qData.company[0])
    }
  }, [qData])

  useEffect(() => {
    if(pointsQData) {
      // console.log(pointsQData.categories)
    }
  },[pointsQData])

  if (!qData) {
    return null
  }

  if (!company) {
    return (
      <div>
        You are not a moderator.
      </div>
    )
  }

  if(pointsQLoading) {
    return null
  }

  return (
    <div>

      <div className="flex flex-row">
        {company?.logo && <img width={50} src={company?.logo} />}
        <h1 className="pl-3">{company?.name}</h1>
      </div>
      <div className="grid">
        <div className='sm:col-12 md:col-4 lg:col-4 xl:col-4 justify-content-center flex align-items-center  text-center'>
          <BigBadge number={pointsQData?.point.total.count.points} text="Points" />
        </div>
        <div className='sm:col-12 md:col-8 lg:col-8 xl:col-4 justify-content-center align-items-center flex flex-column'>
          <h1>Points by category</h1>
          <Donut data={pointsQData}/>
        </div>
        <div className='sm:col-12 md:col-4 lg:col-4 xl:col-4 justify-content-center flex align-items-center  text-center'>

          <BigBadge number={qData.company[0]?.contender?.total?.count} text="Active users" />
        </div>
        <div className='sm:col-12 md:col-8 lg:col-8 xl:col-4 justify-content-center flex'>
          {/* <Donut labels={[{category: "a"}]} /> */}
        </div>
      </div>

    </div>


  )
}



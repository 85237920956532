import axios from "axios";


export const getToken = () => {

        const token = window.localStorage.getItem('twbcToken')
        
        return token
        
    
}
export const superApi = axios.create({
    baseURL: 'https://twbcauth.websoftware.nz',


})

export const forgot = async (email) => {
    const payload = {
        "applicationId": '51524625-4fd3-4d58-bb3f-9a17a5538606',
        "loginId": email,
    }

    const options = {
        method: 'POST',
        url: 'https://twbcauth.websoftware.nz/api/user/forgot-password',
        headers: {
            'Content-Type': 'application/json'
        },
        data: payload
    };

    await axios.request(options).then(function (response) {
        if(response.data) {
            // handleSignInSuccess(response.data.token)
        }
    }).catch(function (error) {
        if(error.response) {
            if(error.response.status === 404) {
                return Promise.reject('Not sure what just happened there')
            }
        }
        return Promise.reject('Login Fails')
    });
}

export const logout = () => {
    window.localStorage.removeItem('twbcToken');
    window.location.reload()
}
export const login = async (username,password) => {

    const handleSignInSuccess = (token) => {
        if(token) {
            window.localStorage.setItem('twbcToken', token)
        } else {
            alert('Something went wrong')
        }
    }

    const payload = {
        "applicationId": '51524625-4fd3-4d58-bb3f-9a17a5538606',
        "loginId": username,
        "password": password
    }

    
        const options = {
            method: 'POST',
            url: 'https://twbcauth.websoftware.nz/api/login',
            headers: {
                'Content-Type': 'application/json'
            },
            data: payload
        };

        await axios.request(options).then(function (response) {
            if(response.data) {
                handleSignInSuccess(response.data.token)
            }
        }).catch(function (error) {
            if(error.response) {
                if(error.response.status === 404) {
                    return Promise.reject('No such username, or wrong password')
                }
            }
            return Promise.reject('Login Fails')
        });


}


import React, { useEffect } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom';

import { Card } from 'primereact/card';
import { TabPanel, TabView } from 'primereact/tabview'
import { Chart } from 'primereact/chart';

import PolarPoints from '../../../../components/PolarPoints';
import Points from '../../Points/Points';
export default function Contender() {
    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const contender = location.state
    useEffect(() => {
        if (location.state === null) {
            navigate('/contenders')
        }

    }, [])

    const fullName = () => {
        return `${contender.profile?.first_name || "?"} ${contender.profile?.last_name || "?"}`
    }
    return (
        <TabView>
            <TabPanel header="Profile">
                <div className='flex md:flex-row flex-column w-full border-none align-items-center'>
                    <div className='flex'>
                        <Card
                            style={{borderWidth: 0}}
                            title={fullName()}
                            subTitle={`${contender.username}`}>

                                Total Points: {contender.point.total.count}

                        </Card>
                    </div>
                    <div className='flex mx-auto'>
                        <PolarPoints contender={contender} />
                    </div>

                </div>
            </TabPanel>
            <TabPanel header="Points">

                <Points account={contender.id} title={fullName()} />
            </TabPanel>
        </TabView>


    )
}


import React from 'react'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { useState, useRef } from 'react'
import { Messages } from 'primereact/messages';
import { Message } from 'primereact/message';
import SignInForm from './components/SignIn/SignInForm'
import ForgotPasswordForm from './components/SignIn/ForgotPasswordForm';


export const Signin = () => {


    const loginError = useRef()
    const [showForgotForm, setShowForgotForm] = useState(false)
    const [sentForgotEmail, setSentForgotEmail] = useState(false)

    


    const handleForgotPassword = () => {
        setShowForgotForm(true)
    }
    
    return (

        <div className="flex surface-ground">
            <div className="surface-section w-full md:w-6 p-6 md:p-8">
            <Messages ref={loginError}></Messages>
                <div className="mb-5">
                    <img src="showcase/images/logo.png" alt="hyper" height={50} className="mb-3" />
                    <div className="text-900 text-3xl font-medium mb-3">{showForgotForm ? "Reset password" : "Sign in here"}</div>
                    <span className="text-600 font-medium mr-2">{showForgotForm ? "We'll send you a reset link" : ""}</span>
                    {/* {!showForgotForm && <a className="font-medium no-underline text-blue-500 cursor-pointer">Create today!</a>} */}
                </div>
                {!showForgotForm ? <SignInForm sentForgotEmail={sentForgotEmail} handleForgotPassword={handleForgotPassword} loginError={loginError} /> : <ForgotPasswordForm sentForgotEmail={sentForgotEmail} setSentForgotEmail={setSentForgotEmail} loginError={loginError} hide={() => setShowForgotForm(false)}/>}
            </div>
            <div className="hidden md:block w-6 bg-no-repeat bg-cover" style={{ backgroundImage: "url('assets/images/blocks/signin/signin.jpg')" }}></div>
        </div>

    )
}


import logo from './logo.svg';
// import './App.css';
import '/node_modules/primeflex/primeflex.css'

import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";                                //icons

import { useEffect } from 'react';

import { Signin } from './Signin';
import { getToken, logout } from './services/authService'

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";

import { items } from './helpers/Menu'
import { Menubar } from 'primereact/menubar'
import Contenders from './Layout/Company/Contenders/Contenders';
import Contender from './Layout/Company/Contenders/Contender/Contender';
import Points from './Layout/Company/Points/Points';
import Company from './Layout/Company/Company';
import ApolloClient from 'apollo-boost';
// import { onError } from 'apollo-link-error'
import { ApolloProvider } from '@apollo/client';


function App() {
  
  if (!getToken()) {
    return (
      <div className="App">
        <Signin />
      </div>
    )
  }

  const headers = {
    'Authorization': `Bearer ${getToken()}`,
    'x-hasura-role': `moderator`
  }

  const client = new ApolloClient({ uri: 'https://twbcgql.websoftware.nz/v1/graphql', onError: ({ operation, response, graphQLErrors, networkError }) => {
    if (graphQLErrors)
    graphQLErrors.map(({ message, path }) =>
    {
      if(message === 'Could not verify JWT: JWTExpired') {
        // Refresh token
        logout()
      }
      console.error(`[GraphQL errorz]: Message: ${message}, Operation: ${operation.operationName}, Path: ${path}`)
    },
      );
      if (networkError) console.error(`[Network error]: ${networkError}`);
  }, headers: headers });

  const start = <a href="/"><img alt="logo" src="showcase/images/logo.png" onError={(e) => e.target.src='https://workplacewellbeing.co.nz/wp-content/uploads/2020/03/workplace-wellbeing-300x138.png'} height="40" className="mr-2"></img></a>;

  return (
    <ApolloProvider client={client}>
    <div className="App min-h-screen p-3 surface-ground">
      <Menubar className="" model={items} start={start}/>
      {/* <div className="mt-3 flex flex-row justify-content-start bg-white p-3 border-round"> */}

      <Router>
        <Routes>
          <Route path='/' element={<Company token={getToken} />} />
          {/* <Route path='/company' element={<Company />} /> */}
          <Route path='/contenders' element={<Contenders />} />
          <Route path='/contenders/:id' element={<Contender />} />
          <Route path='/points' element={<Points />} />
          <Route path='/reports' element={<div>reports</div>} />

        </Routes>
      </Router>
      {/* </div> */}

    </div>
    </ApolloProvider>
  );
}

export default App;

import React, {useState} from 'react'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { validateEmail } from '../../helpers/utils'
import { forgot } from '../../services/authService'
export default function ForgotPasswordForm({ hide,loginError, setSentForgotEmail }) {

    const [email, setEmail] = useState('')

    const handleSubmit = () => {
        if ((validateEmail(email))) {
            forgot(email)
            .then(() => {
                setSentForgotEmail(true)
                loginError.current.show({severity: 'success', detail: "If the account exists, we will send a recovery email"})
            })
            .catch((e) => {
                loginError.current.show({severity: 'error', detail: e})
                console.log(e)
            })
        }
    }
    return (
        <>
            <a href="#" onClick={hide}>{"<< Go back"}</a>
            {/* <label htmlFor="email2" className="block text-900 font-medium mb-2">Email</label> */}
            <InputText placeholder="email@example.com" id="email2" value={email} type="text" className="w-full my-3" onChange={(e) => setEmail(e.target.value)} />
            <Button onClick={handleSubmit} label="Submit" icon="pi pi-user" className="w-full" />
        </>
    )
}

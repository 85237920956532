import React, { useEffect, useState } from "react";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/client";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button'
import { useNavigate } from 'react-router-dom'
import { ProgressSpinner } from 'primereact/progressspinner';
const Q = gql`query MyQuery {
  account {
    id
    username
    profile {
      mobile
      last_name
      id
      first_name
      email
      dog_name
    }
    point: points_aggregate {
      total: aggregate {
        count
      }
    }
    points {
      category
      points
    }
  }
}`
const Contenders = props => {

  const { loading: qLoading, data: qData, error: qError } = useQuery(Q)
  const [contenders, setContenders] = useState([])
  let navigate = useNavigate();

  useEffect(() => {
    if (qData) {
      setContenders(qData.account)
    }
  }, [qData])

  const handleContenderAction = contender => {
    navigate(`${contender.id}`, { state: contender })
  }

  const actionBodyTemplate = (row) => {
    return <Button className="bg-blue-400 border-blue-600" onClick={() => handleContenderAction(row)} type="button" icon="pi pi-cog"></Button>;
  }

  const fullName = (row) => {
    return `${row.profile?.first_name || "?"}  ${row.profile?.last_name || "?"}`
  }

  if(qLoading) {
    return (
    <div className="w-full flex justify-center mt-4"><ProgressSpinner /></div>
    )
  }

  return (
    <div className="">
      <h1 className="pl-3">Contenders</h1>

      <DataTable
        paginator
        value={contenders}
        rows={10}
        rowsPerPageOptions={[1, 10, 25, 50]}
        responsiveLayout="stack"
        emptyMessage="No contenders have signed up yet."
      >
        <Column body={fullName} header="Name"></Column>
        <Column field="username" header="Email"></Column>
        <Column field="profile.mobile" header="Mobile" />
        <Column field="point.total.count" header="Points" />
        <Column headerStyle={{ width: '4rem', textAlign: 'center' }} bodyStyle={{ textAlign: 'center', overflow: 'visible' }} body={actionBodyTemplate} />

      </DataTable>
    </div>
  )
}

export default Contenders